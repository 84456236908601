import {Component, OnDestroy, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {AuthenticationService} from '@app/services/authentication.service';

import * as routes from '@app/routes';
import {DomSanitizer} from '@angular/platform-browser';
import {select, Store} from '@ngrx/store';
import {
  getLoggedInUser,
  IAuthenticationState,
  IProjectState,
  LogoutUser,
  getProjects,
  ProjectListAll,
  ProjectsReportExport,
  getCreateOrCopySuccess,
  ResetAuthState,
  ProjectGet,
  getProject,
  getProjectError,
  getProjectSuccess,
  UploadToSignedUrl,
  IFileUploadState,
  getFileUploadProgress,
  getUploadToSignedUrl
} from '@app/stores';
import {Subject, BehaviorSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LoaderService} from '@app/services/loader.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TokenRefreshComponent} from '@app/_components/token-refresh/token-refresh.component';
import {SocketService} from '@app/services/socket.service';
import {ToastrService} from 'ngx-toastr';
import {thirdpartyPlatforms} from '@app/routes';
import {ReportModel} from "@app/modules/shared/models";
import {versionInfo} from '../../version-info';
import {AwsService} from '@app/services/aws.service';

@Component({
  selector: 'app-component',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  routes = routes;
  currentUser: any;
  inProjectContext: boolean;
  isInfluencer: boolean;
  projectId: number;
  project_id: number;
  projects = [];
  projectList = [];
  project = null;
  projectSearchValue = '';
  imageDefault: any = '../assets/images/project-default.png';
  unsubscriber = new Subject();
  subscription: any;
  eventInit = false;
  currentRoute = '/';
  isActiveProject = false;
  public report: ReportModel = null;
  public socketSubscription: any;
  public exportJobId: number;
  isProjectSearch = false;
  currentVersionInfo: any;
  csvDownloadComplete = false;
  exportModelRef: any;
  modelOpen = false;
  csvUrl: any;
  uniqueImportString: any;
  exportProcess = false;
  public exportProgress: any = {
    status: 'started',
    progress: 0
  };
  fileName: any;
  fileResponse: any[] = [];

  blobObject: any;
  imageSrc: any = "../assets/images/user-image-placeholder.png";

  @ViewChild('csvDownload', {static: true}) csvDownload: TemplateRef<any>;
  public loaderImport = new BehaviorSubject<boolean>(false);


  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private projectStore: Store<IProjectState>,
    public  domSanitizer: DomSanitizer,
    private authenticationStore: Store<IAuthenticationState>,
    private _modalService: NgbModal,
    private socketService: SocketService,
    public loaderService: LoaderService,
    private toastr: ToastrService,
    private awsService: AwsService,
    private fileUploadStore: Store<IFileUploadState>,

  ) {
    this.logOutEventListener = this.logOutEventListener.bind(this);
    translate.setDefaultLang('en');
    this.subscribeStores();
    this.subscribeToSocket();
    // To connect socket on each page
    this.subscription = this.socketService.sourceData
      .subscribe((message: any) => {});
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (!this.router.url.includes('/login')) {
          this.currentRoute = this.router.url;
        }
        this.checkAndRedirectIfUserWithNoClient();
        this.checkAndRedirectIfUserGoingToClientRoute();
        const ar = this.activatedRoute;
        this.projectId = null;
        this.extractProjectId(ar);
        const path = this.location.path().replace(/\?.*$/, '');
        this.inProjectContext = (
          this.currentUser &&
          path.startsWith(`/project`) &&
          path !== `/${routes.projects()}` &&
          path !== `/${routes.createProject()}`
        );

        if (!this.eventInit && localStorage.getItem('currentUser')) {
          window.addEventListener('storage', this.logOutEventListener, false);
        }
      }
    });
    this.imageDefault = this.getSantizeUrl(this.imageDefault);
  }

  checkAndRedirectIfUserWithNoClient() {
    if (this.currentUser) {
      if (this.currentUser.role_user[0].role.name !== 'Admin' && !this.currentUser.client_id) {
        this.toastr.warning('Please select a client');
        this.router.navigate(['/users', 'profile', this.currentUser.id]);
      }
    }
  }

  checkAndRedirectIfUserGoingToClientRoute() {
    if (this.currentUser) {
      if (this.currentUser.role_user[0].role.name !== 'Admin') {
        const path = this.location.path().replace(/\?.*$/, '');
        if (path.startsWith('/clients')) {
          this.router.navigate(['/projects']);
        }
      }
    }
  }

  subscribeStores() {
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
        this.currentUser = null;
        if (user && localStorage.getItem('resetUser') === null) {
          this.currentUser = user;
          this.checkAndRedirectIfUserWithNoClient();
          this.isInfluencer = this.currentUser.user_type === '2';
          this.projectStore.dispatch(ProjectListAll({
            params: {}
          }));
          this.imageSrc = user.profile_img || '../assets/images/project-default.png';
        }
      });

    this.projectStore.pipe(select(getProjects))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(projects => {
        if (projects) {
          this.projects = projects;
          if(!this.isProjectSearch) {
            this.setCurrentProject();
          }
          // if (this.projects && this.isActiveProject) {
          //   this.projectList = projects;
          // }
        }
      });

    this.projectStore.pipe(select(getProject))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(project => {
        if (project) {
          this.project = project;
        }
      });

    this.projectStore.pipe(select(getProjectError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    // this.projectStore.pipe(select(getProjectSuccess))
    //   .pipe(takeUntil(this.unsubscriber))
    //   .subscribe(success => {
    //     if (success) {
    //       this.projects = this.projectList;
    //     }
    //   });

    this.projectStore.pipe(select(getCreateOrCopySuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(createOrCopySuccess => {
        if (createOrCopySuccess) {
          this.projectStore.dispatch(ProjectListAll({
            params: {
              options: JSON.stringify({include_influencers_count: true, include_totals: true})
            }
          }));
        }
      });

    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        // if (data !== undefined && data !== null) {
        //   this.importProgress = {
        //     status: 'uploading',
        //     progress: data
        //   };
        //   if (data >= 100) {
        //     this.importProgress = {
        //       status: 'started',
        //       progress: 0
        //     };
        //   }
        // }
      });

    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.fileResponse && this.fileResponse.length > 0) {
          // this.csvUrl = this.fileResponse[0]['url'].split("?")[0];
          // const a = document.createElement("a");
          // a.href = window.URL.createObjectURL(this.blobObject);
          // a.download = this.fileName;
          // a.click();
          // // this.exportModelRef.close();
          // this.csvDownloadComplete = true;
          // this.exportProgress.status = 'started';
          // this.exportProgress.progress = '0';
        }
      });
  }

  getSantizeUrl(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnInit() {
    this.currentVersionInfo = versionInfo;
    this.loaderService.logout$.subscribe(value => {
      if (value) {
        // const modelRef: any = this._modalService.open(TokenRefreshComponent, {centered: true, backdrop: 'static', keyboard: false});
        // modelRef.result.then((result) => {
        // }, (reason) => {
        // });
      }
    });
    if (!this.eventInit && localStorage.getItem('currentUser')) {
      window.addEventListener('storage', this.logOutEventListener, false);
    }
  }


  subscribeToSocket() {
    this.socketSubscription = this.socketService.sourceData.subscribe(
      (message: any) => {
        //console.log('=====mesaage', message, this.exportJobId );
        if (message?.data?.requesterType === 'projects_report_export' && (!this.exportJobId || message?.data?.id === this.exportJobId) && message?.data?.uniqueImportString === this.uniqueImportString) {
          if (!this.csvDownloadComplete) {
            this.exportProgress.status = 'started';
            this.exportProgress.progress = '0';
          }
          // Set job id
          this.exportJobId = message.data.id;
          this.exportProgress.status = message.data?.result?.status;
          this.exportProgress.progress = message?.data?.result?.progress ? message?.data?.result?.progress : 0;
          // adjust loading state for finished/failed
          const status: string = this.exportProgress?.status;
          if (['finished', 'failed'].includes(this.exportProgress.status)) {
            setTimeout(async () => {
              this.loaderImport.next(false);
              // Keep status
              // Restart progress upon failed
              if (status === 'finished' && !this.exportProcess) {
                this.exportProcess = true;
                if (message?.data?.result?.fileUrl) {
                  this.csvUrl = message?.data?.result?.fileUrl;

                  // Download export file
                  await this.downloadExportFile();

                  this.csvDownloadComplete = true;
                  this.exportProgress.status = 'started';
                  this.exportProgress.progress = '0';
                }
                this.exportJobId = null;
              }
              if (status === 'failed') {
                this.exportModelRef.close();
                this.csvDownloadComplete = false;
                this.exportJobId = null;
              }
            }, 1500);
          }
        }
      },
        (err) => console.error(err),
          () => console.warn('Completed!')
    );
  }

  async downloadExportFile() {
    const fileName = this.csvUrl.split('/').pop();
    const response = await fetch(this.csvUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  }

  getSignedUrlFromFile(file) {
    return new Promise((resolve, reject) => this.awsService.getAwsSignedUrl(file.name, file.type, true).subscribe((resp: any) => {
      resolve({
        url: resp.data.url,
        fileData: file
      });
    }, reject));
  }

  loadAllProjects(showActiveProjects = false, search = '', id = null) {
    this.projectStore.dispatch(ProjectListAll({
      params: {  options: JSON.stringify({orderBy: {'column': 'title', 'dir': 'desc'}}),
        ...(id ? {id: id} : {}),
        search}
    }));
  }

  extractProjectId(ar: ActivatedRoute) {
    ar.params.subscribe(p => {
      this.projectId = p.id || p.projectId;
      if (!this.projectId && ar.firstChild) {
        this.extractProjectId(ar.firstChild);
      } else {
        const project = this.projects.find(e => e.id == this.projectId);
        // if (!project && this.projectId) {
        //   this.loadAllProjects( false, '', this.projectId);
        // }
        this.setCurrentProject();
      }
    });
  }

  setCurrentProject() {
    if (this.projects && this.projectId) {
      this.project = this.projects.find(e => e.id == this.projectId);
    }
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  logout() {
    if (localStorage.getItem('admin_currentUser')) {
      this.loaderService.unsubscribe();
      localStorage.setItem('currentUser', localStorage.getItem('admin_currentUser'));
      localStorage.setItem('loginInTime', localStorage.getItem('admin_loginInTime'));
      localStorage.removeItem('reportPassword');
      localStorage.removeItem('admin_currentUser');
      localStorage.removeItem('admin_loginInTime');
      localStorage.removeItem('skip');
      localStorage.removeItem('influencerSearchValue');
      this.loaderService.startTimer();
      this.authenticationStore.dispatch(ResetAuthState({params: {user: JSON.parse(localStorage.getItem('currentUser'))}}));
      this.router.navigate(['/users']);
    } else {
      localStorage.removeItem('reportPassword');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('loginInTime');
      localStorage.removeItem('skip');
      localStorage.removeItem('projectFilterList');
      localStorage.removeItem('influencerSearchValue');
      this.loaderService.unsubscribe();
      this.authenticationStore.dispatch(LogoutUser());
      this.router.navigate(['/login'], {queryParams: {returnUrl: this.currentRoute}});
    }
  }

  isAdmin() {
    return this.currentUser.user_type === '1';
  }

  isManager(){
    return this.currentUser.role_user[0].role_id  === 7;
  }

  projectRoute(projectId) {
    if (this.isInfluencer) {
      this.router.navigate([this.routes.shareProject(projectId)]);
    } else {
      // this.projectSearchValue = '';
      const currentUrl = this.router.url;
      const urlParts = currentUrl.split('/').filter(item => item);
      if (Number(urlParts[1]) !== Number(projectId)) {
        urlParts[1] = projectId;
        let finalUrl = '/' + urlParts.join('/');
        const campaignStepRoutes = ['campaigns', 'add-influencer', 'influencer/'];
        const influencerRoutes = ['influencers/view'];
        const platformRoutes = ['registration-platforms'];
        let isCampaignRoute = false;
        let isInfluencerRoute = false;
        let isPlatformRoute = false;
        campaignStepRoutes.forEach(item => {
          if (finalUrl.includes(item)) {
            isCampaignRoute = true;
            return true;
          }
        });
        influencerRoutes.forEach(item => {
          if (finalUrl.includes(item)) {
            isInfluencerRoute = true;
            return true;
          }
        });
        platformRoutes.forEach(item => {
          if (finalUrl.includes(item)) {
            isPlatformRoute = true;
            return true;
          }
        });
        if (isCampaignRoute) {
          this.router.navigateByUrl(this.routes.campaigns({projectId}));
        } else if (isInfluencerRoute) {
          this.router.navigateByUrl(this.routes.influencers({projectId}));
        } else if (isPlatformRoute) {
          this.router.navigateByUrl(this.routes.thirdpartyPlatforms({projectId}));
        } else {
          if (finalUrl.includes('statistics')) {
            finalUrl = finalUrl.slice(0, finalUrl.indexOf('?') === -1 ? finalUrl.length : finalUrl.indexOf('?'));
          }
          this.router.navigateByUrl(finalUrl);
        }
      }
    }
  }

  exportProjectsReport() {

    this.uniqueImportString = (new Date().getTime()).toString(36);

    this.exportProcess = false;
    if (!['finished', 'failed'].includes(this.exportProgress.status)) {
      this.csvDownloadComplete = false;
    }
    console.log('====here export');
    const filters = {
      options: JSON.stringify({mode: 'live'}),
      uniqueImportString: this.uniqueImportString,
      v1: (new Date()).getTime()
    };


    if (!this.csvDownloadComplete) {
      this.exportProgress.status = 'started';
      this.exportProgress.progress = '0';
    }
    this.exportModelRef = this._modalService.open(this.csvDownload, {
      centered: true,
      size: 'md',
      keyboard: true,
    });
    this.exportModelRef.result.then((result) => {
    }, (reason) => {
    });
    this.projectStore.dispatch(ProjectsReportExport({data: filters}));
  }

  redirectProject() {
    const url = '/' + this.routes.project(this.project.id);
    if (this.router.url.split('?')[0]) {
      if (this.router.url.split('?')[0] === url) {
        return false;
      }
    } else if (this.router.url === url) {
      return false;
    }
    this.router.navigateByUrl('/' + this.routes.project(this.project.id, {returnUrl: this.router.url}));
  }

  getNameOrEmail() {
    if (this.currentUser) {
      if(this.currentUser.first_name || this.currentUser.last_name) {
        return `${this.currentUser.first_name} ${this.currentUser.last_name}`
      }
      return this.currentUser.email;
    }
  }

  async onSearchProjectList(event = null) {
    this.isProjectSearch = true;
    if (event && event.target.value) {
      this.loadAllProjects(false, this.projectSearchValue);
    } else {
      this.loadAllProjects(false)
      // this.projects = this.projectList;
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.subscription.unsubscribe();

    try {
      window.removeEventListener('storage', this.logOutEventListener);
      this.eventInit = false;
    } catch (e) {}
  }

  logOutEventListener(event) {
    this.eventInit = true;
    if (event.storageArea === localStorage) {
      const currentUser = localStorage.getItem('currentUser');
      if (!currentUser) {
        window.removeEventListener('storage', this.logOutEventListener);
        this.eventInit = false;
        window.location.reload();
      }
    }
  }
}

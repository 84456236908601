import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Campaign, Influencer, InfluencerType} from '@app/modules/shared/models';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder} from '@angular/forms';
import {InfluencerSharedService} from '@app/services/influencer-shared.service';
import {GlobalprojectService} from '@app/services/global.service';
import {SharedService} from '@app/services/shared.service';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {ProjectService} from '@app/services/project.service';
import {ConfigsService} from '@app/services/configs.service';
import {select, Store} from '@ngrx/store';
import {
  getInfluencerError,
  getInfluencersProjectSegment,
  getInfluencersTypes,
  getInfluencerSuccess,
  getProjects,
  IAuthenticationState,
  IInfluencersState,
  InfluencersProjectSegment,
  InfluencersTypeList,
  IProjectState,
  ProjectList,
  ResetInfluencerState,
  getPaginatedInfluencers,
  InfluencersListByProjectPaginate,
  getBulkInfluencers,
  InfluencersDelete,
  ISegmentsState,
  InfluencersBulkStatus,
  InfluencersBulk,
  CampaignGet,
  getCampaignError,
  getCampaignUpdate,
  getCampaign,
  getLoggedInUser,
  ICampaignState, InfluencersExport, UploadToSignedUrl, getFileUploadProgress, getUploadToSignedUrl, IFileUploadState
} from '@app/stores';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DataTableDirective} from 'angular-datatables';
import {CreatableSelectComponent} from '@app/_components/creatable-select';
import {InfluencersImportComponent} from '@app/_components/influencers-import/influencers-import.component';
import {Observable} from 'rxjs/Observable';
import {LoaderService} from '@app/services/loader.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReferralsListComponent} from '@app/_components/referrals-list/referrals-list.component';
import {SegmentListComponent} from '@app/modules/segment/segment-list/segment-list.component';
import * as moment from 'moment-timezone';
import {environment} from '@environments/environment';
import {SocketService} from '@app/services/socket.service';
import {Segment} from '@app/modules/shared/models/segment';
import {SegmentService} from '@app/services/segment.service';
import {TemplateGeneratorService} from "@app/services/template-generator.service";
import {AwsService} from "@app/services/aws.service";
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-influencer-list',
  templateUrl: './influencer-list.component.html',
  styleUrls: ['./influencer-list.component.scss']
})
export class InfluencerListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective, {static: true})
  dtElement: DataTableDirective;

  @Input() selectedInfluencer = null;
  @Input() selectedSourceType = null;
  @Input() selectedSkipped = null;
  @Input() updateSkipped = null;
  @Output() changeSelectedInfluencer = new EventEmitter<any>();
  @Output() changeSelectedSourceType = new EventEmitter<any>();
  @Output() changeSelectedSkipped = new EventEmitter<any>();
  @Output() changeUpdateSkipped = new EventEmitter<any>();
  @Input() selectedSegment = null;
  @Output() changeSelectedSegment = new EventEmitter<any>();
  @Output() sendTestEmailsEmit = new EventEmitter<any>();
  @ViewChild('logsTable', {static: true}) logsTable: TemplateRef<any>;
  selectedEmailType = null;
  influencerTypesById = {};
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  ajaxCallback: any;
  private datatableInstance: DataTables.Api<any>;
  private searchDelay;
  showAll: boolean;
  id: number;
  influencersLocal: Influencer[] = [];
  influencersFile: File;
  submitted = false;
  public events: object;
  segmentsGlobal: any = [];
  filteredSegments: any = [];
  filteredUpdateSegments: any = [];
  unsubscriber = new Subject();
  influencerTypes: InfluencerType[] = [];
  emailType = [{
    id: 'valid',
    name: 'Valid'
  },
    {
      id: 'invalid',
      name: 'Invalid'
    }];
  delete = false;
  multipleDelete = false;
  multipleUpdate = false;
  multipleUnsubscribe = false;
  multipleSkip = false;
  referredByInfluencer = null;
  selectedInfluencerForUpdate = null;
  @ViewChild('influencerSegment') influencerSegment: CreatableSelectComponent;
  loader$: Observable<boolean>;
  _object = Object;
  firstTime = false;
  pageNumber = 0;
  currentUser: any = {};
  importData: Array<Object>;
  isAdmin = false;
  public importJobId: number;
  public socketSubscription: any;
  public exportProgress: any = {
    status: 'started',
    progress: 0
  };
  public importProgress: any = {
    status: 'started',
    progress: 0
  };
  sourceTypes = [
    {name: 'API', value: 'api'},
    {name: 'Manual', value: 'manual'},
    {name: 'Widget', value: 'widget'}
  ];
  skippedType = [
    {name: 'Yes', value: 'yes'},
    {name: 'No', value: 'no'},
    {name: 'Campaign Inclusion', value: 'campaign_inclusion'},
    {name: 'Campaign Email', value: 'campaign_email'},
  ];
  updateSkippedType = [
    {name: 'Remove', value: 'remove' },
    {name: 'Campaign Inclusion', value: 'campaign_inclusion'},
    {name: 'Campaign Email', value: 'campaign_email'},
  ];
  public loaderImport = new BehaviorSubject<boolean>(false);
  public loaderImport$ = this.loaderImport.asObservable();
  importDataTableOptions: any = {
    ordering: false,
    processing: true,
    pagingType: 'full_numbers',
    pageLength: 10,
    paging: true,
    searching: true,
    drawCallback: function (setting) {
      // document.querySelector('.custom-table').classList.remove('blur');
      const totalPages = this.api().page.info().pages;
      if (totalPages <= 1) {
        document.querySelector('.dataTables_paginate').classList.add('d-none');
      } else {
        document.querySelector('.dataTables_paginate').classList.remove('d-none');
      }
    }
  };
  masterSelected: boolean;
  selectAllInfluencer = false;
  pageLength = 0;
  influencerList: Influencer[] = [];
  influencerListIds: any = [];
  influencerCount = 0;
  segments: Segment[] = [];
  checkedInfluencer: any;
  excludedIdList = [];
  includedIdList = [];
  excludedPageList = [];
  includedPageList = [];
  public loaderDelete = new BehaviorSubject<boolean>(false);
  public loaderDelete$ = this.loaderDelete.asObservable();
  influencerError = '';
  segmentError = '';
  campId: number;
  campaignGetAction = false;
  audienceType: number;
  public step;
  segmentIsLoading = false;
  campaign: Campaign = null;
  uniqueImportString: any;
  public exportJobId: number;
  exportProcess = false;
  blobObject: any;
  fileResponse: any[] = [];
  fileName: any;
  csvDownloadComplete = false;
  exportModelRef: any;
  modelOpen = false;
  csvUrl: any;
  @ViewChild('csvDownload', {static: true}) csvDownload: TemplateRef<any>;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationStore: Store<IAuthenticationState>,
    private influencerStore: Store<IInfluencersState>,
    private segmentStore: Store<ISegmentsState>,
    private segmentService: SegmentService,
    private configs: ConfigsService,
    private sharedService: SharedService,
    private influencerSharedService: InfluencerSharedService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private globalprojectService: GlobalprojectService,
    private projectStore: Store<IProjectState>,
    private loaderService: LoaderService,
    private _modalService: NgbModal,
    private readonly socketService: SocketService,
    private campaignStore: Store<ICampaignState>,
    private templateGeneratorService: TemplateGeneratorService,
    private fileUploadStore: Store<IFileUploadState>,
    private awsService: AwsService,
  ) {
    this.socketService.resetSocketData();
  }

  subscribeStores() {
    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          this.currentUser = user;
          this.isAdmin = this.currentUser.role_user[0].role_id === 1;
        }
      );
    this.projectStore.pipe(select(getProjects))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(projects => {
        if (projects) {
          this.events = projects;
        }
      });

    this.influencerStore.pipe(select(getPaginatedInfluencers))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedInfluencers => {
          if (paginatedInfluencers) {
            this.influencersLocal = paginatedInfluencers.list.map((item) => ({
              ...item,
              selected: this.getInfluencerSelectedStatus(item.id, paginatedInfluencers.paging.page)
            }));

            const nonSelectInfluencer = this.influencersLocal.filter((item) => !item.selected);
            this.masterSelected = !(nonSelectInfluencer.length > 0);
            this.influencerCount = Number(paginatedInfluencers.paging.total) - this.excludedIdList.length;

            if (this.ajaxCallback) {
              this.ajaxCallback({
                recordsTotal: paginatedInfluencers.paging.total,
                recordsFiltered: paginatedInfluencers.paging.total,
                data: []
              });
              setTimeout(() => {
                (this.datatableInstance as any).columns.adjust();
              }, 500);
            }
          }
        }
      );

    this.influencerStore.pipe(select(getBulkInfluencers))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerBulk => {
        if (influencerBulk) {
          if (influencerBulk !== true) {
            let errorMessage = '';
            if(this.currentUser.id === influencerBulk.data.userId) {
              if (!influencerBulk.success) {
                this.importJobId = influencerBulk.data?.jobId;
                if (influencerBulk.error) {
                  if (influencerBulk.error.name === 'AppTimeoutError') {
                    errorMessage = 'Timeout';
                  }
                  if (influencerBulk.error.name === 'AppError') {
                    errorMessage = influencerBulk.message;
                  }
                  if (influencerBulk.error.name === 'AppValidationError') {
                    errorMessage = influencerBulk.error.orgMessage || 'Invalid Records';
                    this.importData = influencerBulk.data;
                    this.showDeleteLogs();
                  }
                }
                if (errorMessage) {
                  this.toastr.error(errorMessage, 'Error');
                }
              }
            }
          }
        }
      });

    this.influencerStore.pipe(select(getInfluencersProjectSegment))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(segments => {
        if (segments && segments.length > 0) {
          this.segmentsGlobal = segments;
          if (this.selectedInfluencer) {
            this.filteredSegments = this.segmentsGlobal.filter(item => item.influencer_type_id === this.selectedInfluencer);
          }
        }
      });

    this.influencerStore.pipe(select(getInfluencerError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.influencerStore.pipe(select(getInfluencerSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.delete) {
            this.delete = false;
            this.campId ?  this.loadInfluencersCampaign() : this.loadInfluencers();
          }
        }
      });

    this.influencerStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.influencerTypes = influencerTypes;
          this.influencerTypes.forEach(item => {
            this.influencerTypesById[item.id] = {};
            Object.keys(item.fields).forEach(key => {
              this.influencerTypesById[item.id][key] = true;
            });
          });
        }
      });
    this.influencerStoreSubscribe();
    this.campaignStoreSubscribe();

    this.authenticationStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(user => {
          if (user) {
            this.currentUser = user;
            this.isAdmin = this.currentUser.role_user[0].role_id === 1;
          }
        }
      );

  }

  influencerStoreSubscribe() {
    this.influencerStore.pipe(select(getInfluencersProjectSegment))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(segments => {
        if (segments && segments.length > 0) {
          this.segmentIsLoading = false;
          this.segmentsGlobal = segments;
          if (this.selectedInfluencer) {
            this.filteredSegments = this.segmentsGlobal.filter(item => item.influencer_type_id === this.selectedInfluencer);

            //
            // Pre select the first segment if none is selected
            if (!this.selectedSegment && this.filteredSegments?.length && this.filteredSegments[0]?.id) {
              this.selectedSegment = this.filteredSegments[0]?.id;
              this.getSegmentForUpdate();
              if (document.querySelector('.dataTables_empty')) {
                document.querySelector('.dataTables_empty').innerHTML = this.getMessage();
              }
            }
          }
        }
      });


    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        // if (data !== undefined && data !== null) {
        //   this.importProgress = {
        //     status: 'uploading',
        //     progress: data
        //   };
        //   if (data >= 100) {
        //     this.importProgress = {
        //       status: 'started',
        //       progress: 0
        //     };
        //   }
        // }
      });

    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.fileResponse && this.fileResponse.length > 0) {
          console.log('this.fileResponse', this.fileResponse);
          //this.csvUrl = this.fileResponse[0]['url'].split("?")[0];
          const a = document.createElement("a");
          a.href = this.fileResponse[0].url; // window.URL.createObjectURL(this.blobObject);
          a.download = this.fileName;
          a.click();
          // this.exportModelRef.close();
          this.csvDownloadComplete = true;
          this.exportProgress.status = 'started';
          this.exportProgress.progress = '0';
        }
      });

    this.influencerStore.pipe(select(getPaginatedInfluencers))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedInfluencers => {
          if (paginatedInfluencers && paginatedInfluencers.list.length > 0) {
            if (!this.modelOpen) {
              this.influencersLocal = paginatedInfluencers.list.map((item) => ({
                ...item,
                selected: this.getInfluencerSelectedStatus(item.id, paginatedInfluencers.paging.page)
              }));

              const nonSelectInfluencer = this.influencersLocal.filter((item) => !item.selected);
              this.masterSelected = !(nonSelectInfluencer.length > 0);
              this.influencerCount = Number(paginatedInfluencers.paging.total) - this.excludedIdList.length;
            }
          } else {
            this.influencersLocal = [];
            this.influencerCount = 0;
          }
        }
      );

    this.influencerStore.pipe(select(getInfluencerSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.delete) {
            this.delete = false;
            this.loadInfluencers();
          }
        }
      });

    this.influencerStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.influencerTypes = influencerTypes;
          this.influencerTypes.forEach(item => {
            this.influencerTypesById[item.id] = {};
            Object.keys(item.fields).forEach(key => {
              this.influencerTypesById[item.id][key] = true;
            });
          });
        }
      });
  }

  campaignStoreSubscribe() {
    this.campaignStore.pipe(select(getCampaign))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(campaign => {
        if (campaign && this.campaignGetAction) {
          this.campaignGetAction = false;
          this.campaign = campaign;
          this.templateGeneratorService.setCampaignValue(campaign);
          this.step = {
            ...this.step,
            params: {
              ...this.step.params,
              campaignId: campaign.id,
              campaignName: campaign.name
            }
          };
          if (campaign.segments && campaign.segments.length > 0) {
            const segment = campaign.segments[0];
            if (!this.selectedInfluencer) {
              this.selectedInfluencer = this.audienceType || segment.influencer_type_id;
            }

            this.filteredSegments = this.segmentsGlobal.filter(item => item.influencer_type_id === this.selectedInfluencer);
            this.selectedSegment = segment.id;
            this.route.queryParams.subscribe(async (queryParams) => {
              if(queryParams.selectedSegmentUrl){
                this.selectedSegment = queryParams.selectedSegmentUrl;
                this.selectedInfluencer = Number(queryParams.selectedInfluencerUrl);
                this.filteredSegments = this.segmentsGlobal.filter(item => item.influencer_type_id === this.selectedInfluencer);
                // alert(this.selectedSegment)
              }
            });


            this.campId ? this.loadInfluencersCampaign() : this.loadInfluencers();

            this.getSegmentForUpdate();
          } else {
            if (!this.selectedInfluencer) {
              if (this.audienceType) {
                this.selectedInfluencer = Number(this.audienceType);
              } else if (this.influencerTypes.length > 0) {
                this.selectedInfluencer = this.influencerTypes[0].id;
              }
              // Get segments
              this.getSegments();
            }
          }
          if (!this.selectedInfluencer && this.audienceType) {
            this.selectedInfluencer = Number(this.audienceType);
          }
          this.dtTrigger.next();
        }
      });


  }

  subscribeToSocket() {
    this.socketSubscription = this.socketService.sourceData.subscribe(
      (message: any) => {
        if (message?.data?.requesterType === 'influencer_bulk_operations' && (!this.importJobId || message?.data?.id === this.importJobId)) {

          //
          // Set job id
          this.importJobId = message.data.id;

          this.importProgress.status = message.data?.status;
          this.importProgress.progress = message?.data?.progress ? message?.data?.progress : 0;
          // adjust loading state for finished/failed
          if (['finished', 'failed'].includes(this.importProgress.status)) {
            setTimeout(() => {
              this.loaderImport.next(false);
              // Keep status
              const status: string = this.importProgress?.status;
              // Restart progress upon failed
              if (status === 'failed') {
                this.bulkStatus();
                this.importProgress = {
                  status: 'started',
                  progress: 0
                };
                this.multipleDelete = false;
                this.multipleUpdate = false;
                this.multipleUnsubscribe = false;
                this.multipleSkip = false;
                this.masterSelected = false;
                this.selectAllInfluencer = false;
                this.includedIdList = [];
                this.excludedPageList = [];
                this.includedPageList = [];
                this.excludedIdList = [];
              } else if (status !== 'failed') {
                this.importJobId = null;
                if (status !== 'started') {
                  if (this.multipleDelete) {this.toastr.success('Delete successful', 'Success'); }
                  if (this.multipleUpdate) {this.toastr.success('Update successful', 'Success'); }
                  if (this.multipleUnsubscribe) {this.toastr.success('Unsubscribe successful', 'Success'); }
                  if (this.multipleSkip) {this.toastr.success('Influncer skip type change successful', 'Success'); }
                  this.pageNumber = 0;
                  this.campId ?  this.loadInfluencersCampaign() : this.loadInfluencers();
                  this.importProgress = {
                    status: 'started',
                    progress: 0
                  };
                  this.multipleDelete = false;
                  this.multipleUpdate = false;
                  this.multipleUnsubscribe = false;
                  this.multipleSkip = false;
                  this.masterSelected = false;
                  this.selectAllInfluencer = false;
                  this.includedIdList = [];
                  this.excludedPageList = [];
                  this.includedPageList = [];
                  this.excludedIdList = [];                }
              }
            }, 1500);
          }
        }
        if (message?.data?.requesterType === 'influencer_export' && (!this.exportJobId || message?.data?.id === this.exportJobId) && message?.data?.uniqueImportString === this.uniqueImportString) {
          if(!this.csvDownloadComplete) {
            this.exportProgress.status = 'started';
            this.exportProgress.progress = '0';
          }
          // Set job id
          this.exportJobId = message.data.id;
          this.exportProgress.status = message.data?.result?.status;
          this.exportProgress.progress = message?.data?.result?.progress ? message?.data?.result?.progress : 0;
          // adjust loading state for finished/failed
          const status: string = this.exportProgress?.status;
          if (['finished', 'failed'].includes(this.exportProgress.status)) {
            setTimeout( async () => {
              this.loaderImport.next(false);
              // Keep status
              // Restart progress upon failed
              if (status === 'finished' && !this.exportProcess) {
                this.exportProcess = true;
                // const content = '\ufeff' + message?.data?.result?.headers + "\n" + message?.data?.result?.rows;
                // this.blobObject = new Blob([content], { type: 'text/csv' });
                // const file = new File([this.blobObject], message?.data?.result?.fileName, {
                  // type: this.blobObject.type
                // });
                // this.fileName = message?.data?.result?.fileName;
                //console.log('message?.data?.result', message?.data?.result);
                if (message?.data?.result?.fileUrl) {
                  this.csvUrl = message?.data?.result?.fileUrl;
                  // this.fileResponse = await Promise.all([
                    // this.getSignedUrlFromFile(file),
                  // ]);
                  //this.fileUploadStore.dispatch(UploadToSignedUrl({ files: [{url: message?.data?.result?.fileUrl}] }));
                  // this.fileUploadStore.dispatch(UploadToSignedUrl({ files: message?.data?.result?.fileUrl }));

                  // Download export file
                  await this.downloadExportFile();

                  this.csvDownloadComplete = true;
                  this.exportProgress.status = 'started';
                  this.exportProgress.progress = '0';
                }
                this.exportJobId = null;
              }
              if (status === 'failed') {
                this.exportModelRef.close();
                this.csvDownloadComplete = false;
                this.exportJobId = null;
              }
            },1500);
          }
        }
      },
      (err) => console.error(err),
      () => console.warn('Completed!')
    );
  }

  async downloadExportFile() {
    const fileName = this.csvUrl.split('/').pop();
    const response = await fetch(this.csvUrl);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  }

  getSignedUrlFromFile(file) {
    return new Promise((resolve, reject) => this.awsService.getAwsSignedUrl(file.name, file.type, true).subscribe((resp: any) => {
      resolve({
        url: resp.data.url,
        fileData: file
      });
    }, reject));
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    window.onunload = function () {
      localStorage.removeItem('influencerSearchValue');
    };
    this.datatableSettings();

    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: any) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
    this.loader$ = this.loaderService.loader$;
    // document.querySelector('.custom-table').classList.add('blur');
    this.influencerStore.dispatch(ResetInfluencerState({
      params: {error: null, influencersProjectSegment: [], influencerTypes: [], paginatedInfluencers: null, influencerBulk: null}
    }));

    this.route.params.subscribe((params) => {
      this.route.queryParams.subscribe(async (queryParams) => {
        this.campId = queryParams.campId;
        this.audienceType = queryParams.audienceType;
        this.step = {title: 'Influencers', params: {projectId: params.id, campaignId: null, campaignName: null}};
        this.subscribeStores();
        this.subscribeToSocket();

        if (this.campId) {
          this.campaignGetAction = true;
          this.campaignStore.dispatch(CampaignGet(queryParams.campId, {
            options: JSON.stringify({
              include_segments: true,
              include_email_status: true
            })
          }));
        }

      });
    });
    this.firstTime = true;
    this.route.paramMap.subscribe(params => {
      this.id = this.campId ? +params.get('id') : +params.get('projectId');
      this.influencersLocal = [];
      this.setProjectId(this.id)  ;
      this.globalprojectService.updateProjectId(this.id);
      this.influencerStore.dispatch(InfluencersTypeList({params: {influencer_fields: true}}));
      if (!this.firstTime) {
        this.campId ?  this.loadInfluencersCampaign() : this.loadInfluencers();
      }
      this.firstTime = false;
    });

  }

  setProjectId(projectId) {
    this.influencerStore.dispatch(InfluencersProjectSegment({projectId}));

  }


  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  getSegmentForUpdate() {
    this.filteredUpdateSegments = this.filteredSegments.filter(item => item.id !== this.selectedSegment);
  }

  getSegments() {
    if (!this.segmentIsLoading) {
      this.segmentIsLoading = true;
      const projectId = this.id;
      this.influencerStore.dispatch(InfluencersProjectSegment({projectId}));
    }
  }
  sendTestEmails(email) {
    this.sendTestEmailsEmit?.emit(email);
  }

  datatableSettings() {
    this.dtOptions = {
      "bDestroy": true,
      scrollCollapse: true,
      pagingType: 'full_numbers',
      responsive: false,
      ordering: true,
      columnDefs: [{orderable: false, targets: 'no-sort'}],
      processing: true,
      pageLength: 100,
      paging: true,
      lengthMenu: [50, 100, 500, 1000],
      serverSide: true,
      scrollX: true,
      searching: true,
      scrollY: "60vh",
      fixedHeader: {
        header: true,
        footer: true,
        headerOffset: 55 ,
      },
      fixedColumns: {
        right: 1,
        left: 0
      },
      drawCallback: function (setting) {
        const totalPages = this.api().page?.info()?.pages || 0;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: any) => {
          this.datatableInstance = dtInstance;
          this.ajaxCallback = callback;
          this.pageLength = dataTablesParameters.length;
          this.pageNumber = (dataTablesParameters.start / this.pageLength);
          (this.datatableInstance as any).page.len(this.pageLength);
          const searchBox = $('div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT search.DT');
          setTimeout(() => {
            searchBox.val(localStorage.getItem('influencerSearchValue'));
          }, 10);

          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                localStorage.setItem('influencerSearchValue', search);
                (this.datatableInstance as any).search(search).draw();
              } else {
                localStorage.removeItem('influencerSearchValue')
              }
            }, 10);
          });
          const orderBy = {
            ...dataTablesParameters.order[0],
            column: 'updated_at'
          };

          searchBox.on('search.DT', () => {
            localStorage.removeItem('influencerSearchValue');
            this.campId ?  this.loadInfluencersCampaign(this.pageLength) : this.loadInfluencers(this.pageLength);
          })

          if (localStorage.getItem('influencerSearchValue')) {
            dataTablesParameters.search.value = localStorage.getItem('influencerSearchValue');
          }
          this.campId ? this.loadInfluencersCampaign(this.pageLength, dataTablesParameters.search.value, orderBy) : this.loadInfluencers(this.pageLength, dataTablesParameters.search.value, orderBy);
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null},
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}
      ],
      order: [[7, 'desc']],
    };
  }

  checkReferredThroughTrackerSource(source = null) {
    let message = '';
    let isRedBackground = false;
    if (source) {
      if (source === 'ip' || source === 'client_fingerprint') {
        message = 'Conversion tracking source is not accurate, make sure the tracking script is added to all pages through the registration process on your site.';
        isRedBackground = true;
      } else if (source !== 'url' && source !== 'on_site_cookie') {
        message = 'Conversion tracking source is not optimal, make sure the tracking script is added to all pages through the registration process on your site.';
      }
    }
    return {message, isRedBackground};
  }

  getMessage() {
    if (!this.selectedInfluencer && !this.selectedSegment) {
      return 'Please select a influencer type';
    } else if (this.selectedInfluencer && !this.selectedSegment) {
      return 'Please select a segment';
    } else {
      return `Add individual influencer or Upload them in bulk. <br>
                         You can skip this step for now, you'll have the chance to add them before launching your campaign.
                        <div>
                          <a title="Add" class="cursor-pointer" onclick="window.dispatchEvent(new CustomEvent('emptyTableCallback', {detail: 'add'}))">
                            <img class="" src="../../assets/images/add-ic.png">
                          </a>
                          <a onclick="window.dispatchEvent(new CustomEvent('emptyTableCallback', {detail: 'import'}))" class="cursor-pointer" title="Import">
                            <img class="mx-2" src="../../assets/images/export-normal.png">
                          </a>
                          <a onclick="window.dispatchEvent(new CustomEvent('emptyTableCallback', {detail: 'skip'}))" class="cursor-pointer btn btn-default btn-sm" title="Skip">
                            Skip for now
                          </a>
                        </div>`;
    }
  }

  loadInfluencers(perPage = 100, search = '', orderBy = {'column': 'updated_at', 'dir': 'desc'}) {
    this.influencerStore.dispatch(InfluencersListByProjectPaginate({
      params: {
        options: JSON.stringify({includePagination: true, orderBy}),
        project_id: this.id,
        ...(this.selectedInfluencer ? {influencer_type_id: this.selectedInfluencer} : {}),
        ...(this.referredByInfluencer ? {referred_by: Number(this.referredByInfluencer.id)} : {}),
        ...(this.selectedSegment ? {project_influencer_segment_id: this.selectedSegment} : {}),
        ...(this.selectedSourceType ? {source: this.selectedSourceType} : {}),
        ...(this.selectedSkipped ? {skip: this.selectedSkipped} : {}),
        ...(this.selectedEmailType ? {email_address_validation_status	: this.selectedEmailType} : {}),
        page: this.pageNumber + 1,
        perPage,
        search
      }
    }));
  }

  loadInfluencersCampaign(perPage = 100, search = '', orderBy = {'column': 'updated_at', 'dir': 'desc'}) {
    this.influencerStore.dispatch(InfluencersListByProjectPaginate({
      params: {
        options: JSON.stringify({includePagination: true, orderBy, includeEmailOpen: true, includeEmailClick: true, includeCampaignInfluencer: true}),
        project_id: this.id,
        campaign_id: this.campId,
        ...(this.selectedInfluencer ? {influencer_type_id: this.selectedInfluencer} : {}),
        ...(this.referredByInfluencer ? {referred_by: Number(this.referredByInfluencer.id)} : {}),
        ...(this.selectedSegment ? {project_influencer_segment_id: this.selectedSegment} : {}),
        ...(this.selectedSourceType ? {source: this.selectedSourceType} : {}),
        ...(this.selectedSkipped ? {skip: this.selectedSkipped} : {}),
        ...(this.selectedEmailType ? {email_address_validation_status	: this.selectedEmailType} : {}),
        page: this.pageNumber + 1,
        perPage,
        search
      }
    }));
  }
  getInfluencerSelectedStatus(id, pageNumber) {
    let result;
    if (this.selectAllInfluencer) {
      if (this.excludedPageList.includes(Number(pageNumber))) {
        result = !this.excludedIdList.includes(id);
      } else {
        result = true;
      }
    } else {
      // result = this.includedPageList.includes(Number(pageNumber));
      if (!this.includedPageList.includes(Number(pageNumber))) {
        result = this.includedIdList.includes(id);
      } else {
        result = true;
      }
    }
    return result;
  }


  loadAllEvents() {
    this.projectStore.dispatch(ProjectList());
  }

  viewInfluencer(id, itempKey, influencerId) {
    this.router.navigate([
      '/projects/' + id + '/influencers/view/' + influencerId
    ],
      { queryParams: { selectedInfluencerType: this.selectedInfluencer, selectedSegment: this.selectedSegment,campaignId: this.campId }}
    );
  }

  public changeStatus(influencer) {
    Swal({
      title: 'Are you sure?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        const {id} = influencer;
        this.delete = true;
        this.influencerStore.dispatch(InfluencersDelete({influencerId: id}));
      }
    });
  }

  deleteSelectedInfluencer() {
    Swal({
      title: 'Are you sure you want to delete from the whole project? this will permanently remove them from all related campaigns and clear their stats as well',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      width: '50%'
    }).then(result => {
      if (result.value) {
        this.multipleDelete = true;
        this.loaderImport.next(true);

        const data = {
          influencerList: !this.selectAllInfluencer ? this.includedIdList : null,
          influencerType:  this.selectedInfluencer,
          influencerSegment: this.selectedSegment,
          project_id: this.id,
          selectAll: this.selectAllInfluencer,
          excludedInfluencers: this.excludedIdList,
          bulkAction: 'delete'
        };
        this.influencerStore.dispatch(InfluencersBulk({data: data}));
      }
    });
  }

  updateSelectedInfluencer() {
    Swal({
      title: 'Are you sure you want to move Selected influencers to another segment?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.multipleUpdate = true;
        this.loaderImport.next(true);
        const data = {
          influencerList: !this.selectAllInfluencer ? this.includedIdList : null,
          influencerType: this.selectedInfluencer,
          influencerSegment: this.selectedSegment,
          project_id: this.id,
          selectAll: this.selectAllInfluencer,
          excludedInfluencers: this.excludedIdList,
          updateData: {project_influencer_segment_id: this.selectedInfluencerForUpdate},
          bulkAction: 'update'
        };
        this.influencerStore.dispatch(InfluencersBulk({data: data}));
      }
    });
  }

  public unsubscribeSelectedInfluencer() {
    Swal({
      title: 'Write a reason for unsubscribing:',
      input: 'text',
      confirmButtonText: 'Unsubscribe',
      showCancelButton: true,
      customClass: 'unsubscribe-pop-up'
    }).then((result) => {
      if (result.value === '' || result.value === null) {
        Swal({
          title: 'You need to write reason for unsubscribing!',
          type: 'warning',
        }).then((res) => {
          if (res.value) {
            this.unsubscribeSelectedInfluencer();
          }
        });
      }
        if (result.value) {
          let data = {};
          this.multipleUnsubscribe = true;
          this.loaderImport.next(true);
          data = {
            influencerList: !this.selectAllInfluencer ? this.includedIdList : null,
            influencerType: this.selectedInfluencer,
            influencerSegment: this.selectedSegment,
            project_id: this.id,
            selectAll: this.selectAllInfluencer,
            excludedInfluencers: this.excludedIdList,
            bulkAction: 'unsubscribe',
            reason: result.value
          };
          this.influencerStore.dispatch(InfluencersBulk({data: data}));
        }
    });
  }

  public skipSelectedInfluencer() {
    Swal({
      title: 'Are you sure you want to update influncer skip type?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.multipleSkip = true;
        this.loaderImport.next(true);
        const data = {
          influencerList: !this.selectAllInfluencer ? this.includedIdList : null,
          influencerType: this.selectedInfluencer,
          influencerSegment: this.selectedSegment,
          project_id: this.id,
          selectAll: this.selectAllInfluencer,
          excludedInfluencers: this.excludedIdList,
          updateData: {skip: this.updateSkipped === 'remove' ? null : this.updateSkipped},
          bulkAction: 'update'
        };
        this.influencerStore.dispatch(InfluencersBulk({data: data}));
      }
    });
  }

  segmentForUpdate(event) {
    // this.selectAllInfluencer = false;
    // this.masterSelected = false;
    // this.influencerListIds = [];
    if (event) {
      this.selectedInfluencerForUpdate = Number(event.value);
    }
  }

  getInfluencerType(influencerTypeId) {
    const type = this.influencerTypes.find(item => item.id === influencerTypeId);
    return type ? 'Select ' + type.name : '';
  }

  getInfluencerTypeSlug(influencerTypeId) {
    const type = this.influencerTypes.find(item => item.id === influencerTypeId);
    return type ? type.slug : '';
  }

  getInfluencerSegment(segmentId) {
    const type = this.segmentsGlobal.find(item => item.id === segmentId);
    return type ? type.name : '';
  }

  showAllColumns(event) {
    this.showAll = event.target.checked;
    setTimeout(() => {
      (this.datatableInstance as any).columns.adjust();
    }, 200);
  }

  filterInfluencers(event) {
    this.masterSelected = false;
    this.selectAllInfluencer = false;
    this.includedIdList = [];
    this.excludedPageList = [];
    this.includedPageList = [];
    this.excludedIdList = [];
    this.influencerSegment.clearSelection();
    this.selectedInfluencer = null;
    this.selectedSegment = null;
    if (event) {
      this.selectedInfluencer = Number(event.value);
    }
    this.changeSelectedInfluencer?.emit(this.selectedInfluencer);
    this.filteredSegments = [];
    if (this.selectedInfluencer) {
      this.filteredSegments = this.segmentsGlobal.filter(item => item.influencer_type_id === this.selectedInfluencer);
    }
    this.pageNumber = 0;

    this.campId ?  this.loadInfluencersCampaign() : this.loadInfluencers();
  }

  filterInfluencerSourceType(event) {
    this.masterSelected = false;
    this.selectAllInfluencer = false;
    this.includedIdList = [];
    this.excludedPageList = [];
    this.includedPageList = [];
    this.excludedIdList = [];
    this.selectedSourceType = null;
    if (event) {
      this.selectedSourceType = event.value;
    }
    this.changeSelectedSourceType?.emit(this.selectedSourceType);
    this.pageNumber = 0;

    this.campId ?  this.loadInfluencersCampaign() : this.loadInfluencers();
  }

  filterSkipped(event) {
    this.masterSelected = false;
    this.selectAllInfluencer = false;
    this.includedIdList = [];
    this.excludedPageList = [];
    this.includedPageList = [];
    this.excludedIdList = [];
    this.selectedSkipped = null;
    if (event) {
      this.selectedSkipped = event.value;
    }
    this.changeSelectedSkipped?.emit(this.selectedSkipped);
    this.pageNumber = 0;

    this.campId ?  this.loadInfluencersCampaign() : this.loadInfluencers();
  }

  getInfluncerSkippedType(event) {
    this.updateSkipped = null;
    if (event) {
      this.updateSkipped = event.value;
    }
    this.changeUpdateSkipped?.emit(this.updateSkipped);
  }

  filterSegments(event) {
    this.masterSelected = false;
    this.selectAllInfluencer = false;
    this.includedIdList = [];
    this.excludedPageList = [];
    this.includedPageList = [];
    this.excludedIdList = [];
    this.pageNumber = 0;    this.pageNumber = 0;
    if (this.selectedInfluencer) {
      this.selectedSegment = null;
      this.selectedInfluencerForUpdate = null;
      if (event) {
        this.selectedSegment = Number(event.value);
      }
      this.changeSelectedSegment?.emit(this.selectedSegment);
      this.filteredUpdateSegments = this.filteredSegments.filter(item => item.id !== this.selectedSegment);
      this.campId ?  this.loadInfluencersCampaign() : this.loadInfluencers();

    }
  }

  filterEmailType(event) {
    this.selectedEmailType = null;
    this.masterSelected = false;
    this.selectAllInfluencer = false;
    this.includedIdList = [];
    this.excludedPageList = [];
    this.includedPageList = [];
    this.excludedIdList = [];
    this.pageNumber = 0;    this.pageNumber = 0;
    if (event) {
      this.selectedEmailType = event.value;
    }
    this.campId ?  this.loadInfluencersCampaign() : this.loadInfluencers();
  }

  importInfluencers() {
    this.loader$ = null;
    const modelRef = this._modalService.open(InfluencersImportComponent, {
      centered: false,
      size: 'xl',
      keyboard: true
    });
    // modelRef.componentInstance.campId = this.campId;
    modelRef.componentInstance.influencerTypes = this.influencerTypes;
    modelRef.componentInstance.segments = this.segmentsGlobal;
    modelRef.componentInstance.projectId = this.id;
    modelRef.componentInstance.selectedInfluencer = this.selectedInfluencer;
    modelRef.result.then((result) => {
      this.loader$ = this.loaderService.loader$;
      this.campId ?  this.loadInfluencersCampaign() : this.loadInfluencers();
    }, (reason) => {
      this.loader$ = this.loaderService.loader$;
      this.campId ?  this.loadInfluencersCampaign() : this.loadInfluencers();
    });
  }

  // exportInfluencers() {
  //   const commonFields = ['id', 'name', 'description', 'pageViews', 'referred_count', 'public_info_update_page_url', 'source', 'created_at', 'updated_at'];
  //   const fieldsWithConditions = ['email', 'image', 'influencer_type_id', 'project_influencer_segment_id',
  //     'registration_url', 'company', 'title', 'first_name', 'last_name',
  //     'phone', 'video_url', 'booth_number', 'job_title', 'sessions',
  //     'custom_1', 'custom_2', 'custom_3', 'custom_4', 'custom_5',
  //     'custom_6', 'custom_7', 'custom_8', 'custom_9', 'custom_10',
  //     'referred_by', 'referred_by_campaign_id', 'referred_through_tracker_source'];
  //
  //   let fieldsToInclude = [];
  //   // if (this.showAll) {
  //     fieldsToInclude = fieldsWithConditions.filter(fieldName => (!this.selectedInfluencer ||
  //       (this.selectedInfluencer && this.influencerTypesById[this.selectedInfluencer][fieldName])));
  //   // }
  //   fieldsToInclude = [...commonFields, ...fieldsToInclude];
  //   const filters = {
  //     options: JSON.stringify({includePagination: false, orderBy: {'column': 'created_at', 'dir': 'desc'}}),
  //     fieldsToInclude: JSON.stringify(fieldsToInclude),
  //     project_id: this.id,
  //     ...(this.selectedInfluencer ? {influencer_type_id: this.selectedInfluencer} : {}),
  //     ...(this.referredByInfluencer ? {referred_by: Number(this.referredByInfluencer.id)} : {}),
  //     ...(this.selectedSegment ? {project_influencer_segment_id: this.selectedSegment} : {}),
  //     timezone: this.currentUser.timezone,
  //     v1: (new Date()).getTime()
  //   };
  //
  //   const params = Object.keys(filters).map(key => `${key}=${filters[key]}`).join('&');
  //   window.open(`${environment.apiUrl}/api/v1/influencer/export/download?${params}`, '_blank');
  // }

  exportInfluencers(exportType) {
    const commonFields = ['id', 'name', 'description', 'pageViews', 'referred_count', 'campaign_influencer_page_url', 'campaign_influencer_share_page_url', 'public_info_update_page_url', 'source', 'email_open', 'email_click', 'email_status', 'created_at', 'updated_at', 'email_open_tracker_html', 'email_open_tracker_url', 'share_link_tracker_url', 'personalized_images'];
    let fieldsWithConditions = ['email', 'image', 'influencer_type_id', 'project_influencer_segment_id',
      'registration_url', 'company', 'first_name', 'last_name',
      'phone', 'video_url', 'booth_number', 'job_title', 'sessions',
      'custom_1', 'custom_2', 'custom_3', 'custom_4', 'custom_5',
      'custom_6', 'custom_7', 'custom_8', 'custom_9', 'custom_10'];

    if (!this.campId) {
      fieldsWithConditions = [...fieldsWithConditions, 'referred_by', 'referred_by_campaign_id', 'referred_through_tracker_source'];
    }

    this.uniqueImportString = (new Date().getTime()).toString(36);

    this.exportProcess = false;
    if (!['finished', 'failed'].includes(this.exportProgress.status)) {
      this.csvDownloadComplete = false;
    }
    let fieldsToInclude = [];
    // if (this.showAll) {
    fieldsToInclude = fieldsWithConditions.filter(fieldName => (!this.selectedInfluencer ||
      (this.selectedInfluencer && this.influencerTypesById[this.selectedInfluencer][fieldName])));
    // }
    fieldsToInclude = [...commonFields, ...fieldsToInclude];

    if (this.isAdmin) {
      const emailStatusIndex = fieldsToInclude.indexOf('email_status');
      if (emailStatusIndex) {
        fieldsToInclude.splice(emailStatusIndex + 1, 0,  'failed_reason', 'failed_reason_info');
      } else {
        fieldsToInclude = [...fieldsToInclude, 'failed_reason', 'failed_reason_info'];
      }
    }

    console.log('====fieldsToInclude', fieldsToInclude);
    const filters = {
      exportType,
      options: JSON.stringify({includePagination: false, orderBy: {'column': 'created_at', 'dir': 'desc'}}),
      fieldsToInclude: JSON.stringify(fieldsToInclude),
      project_id: this.id,
      uniqueImportString: this.uniqueImportString,
      ...(this.campId ? {campaign_id: this.campId} : {}),
      ...(this.selectedInfluencer ? {influencer_type_id: this.selectedInfluencer} : {}),
      ...(this.selectedSegment ? {project_influencer_segment_id: this.selectedSegment} : {}),
      timezone: this.currentUser.timezone,
      v1: (new Date()).getTime()
    };

    const params = Object.keys(filters).map(key => `${key}=${filters[key]}`).join('&');
    if(!this.csvDownloadComplete) {
      this.exportProgress.status = 'started';
      this.exportProgress.progress = '0';
    }
    this.exportModelRef = this._modalService.open(this.csvDownload, {
      centered: true,
      size: 'md',
      keyboard: true,
    });
    this.exportModelRef.result.then((result) => {
    }, (reason) => {
    });


    this.influencerStore.dispatch(InfluencersExport({data: filters}));

    // setTimeout(() => {
    //   modelRef.close();
    // },1500);
    // window.open(`${environment.apiUrl}/api/v1/influencer/export/download?${params}`);

  }

  showReferralList(id: number, name: string) {
    this.loader$ = null;
    const modelRef = this._modalService.open(ReferralsListComponent, {
      centered: false,
      size: 'xl',
      keyboard: true
    });
    modelRef.componentInstance.influencerId = id;
    modelRef.componentInstance.influencerName = name;
    modelRef.componentInstance.influencerTypes = this.influencerTypes;
    modelRef.componentInstance.segmentsGlobal = this.segmentsGlobal;
    modelRef.result.then((result) => {
      this.loader$ = this.loaderService.loader$;
    }, (reason) => {
      this.loader$ = this.loaderService.loader$;
    });
  }

  manageSegments() {
    this.loader$ = null;
    const modelRef = this._modalService.open(SegmentListComponent, {
      centered: false,
      size: 'xl',
      keyboard: true
    });
    modelRef.componentInstance.projectId = this.id;
    modelRef.componentInstance.selectedInfluencer = this.selectedInfluencer;
    modelRef.result.then((result) => {
      if(result && result.segment) {
        this.selectedSegment = Number(result.segment?.id);
        this.campId ? this.loadInfluencersCampaign() : this.loadInfluencers();
      }
      this.loader$ = this.loaderService.loader$;
      this.setProjectId(this.id)  ;
    }, (reason) => {
      this.loader$ = this.loaderService.loader$;
      this.setProjectId(this.id);
    });
  }

  formatInsertTime(insertTime) {
    insertTime = moment(insertTime, 'YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
    if (insertTime.isValid()) {
      return insertTime.format('DD-MM-YYYY hh:mm a');
    }
    return '-';
  }

  formatMoment(insertTime) {
    insertTime = moment(insertTime, 'YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
    if (insertTime.isValid()) {
      return insertTime;
    }
    return null;
  }

  getCreateUpdateInfo(user) {
    if (user) {
      if ((user.hasOwnProperty('first_name') && user.first_name) || (user.hasOwnProperty('last_name') && user.last_name)) {
        return `${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`;
      } else if (user.hasOwnProperty('email') && user.email) {
        return user.email;
      }
    }
  }

  filterReferredByInfluencer(influencer) {
    this.pageNumber = 0;
    if (influencer) {
      this.referredByInfluencer = influencer;
    }
    this.filteredSegments = [];
    if (this.referredByInfluencer) {
      this.filteredSegments = this.segmentsGlobal.filter(item => item.referredBy == Number(this.referredByInfluencer.id));
    }
    this.campId ?  this.loadInfluencersCampaign() : this.loadInfluencers();
  }

  clearReferredByInfluencer(event: object) {
    if (event) {
      this.referredByInfluencer = null;
      this.campId ?  this.loadInfluencersCampaign() : this.loadInfluencers();
    }
  }

  checkUncheckAllInfluencer() {
    if (!this.masterSelected) {
      if (!this.excludedPageList.includes(this.pageNumber + 1)) {
        this.excludedPageList.push(this.pageNumber + 1);
      }
      const index = this.includedPageList.indexOf(this.pageNumber + 1);
      if (index > -1) {
        this.includedPageList.splice(index, 1);
      }
    } else {
      const index = this.excludedPageList.indexOf(this.pageNumber + 1);
      if (index > -1) {
        this.excludedPageList.splice(index, 1);
      }
      if (!this.includedPageList.includes(this.pageNumber + 1)) {
        this.includedPageList.push(this.pageNumber + 1);
      }
    }

    this.influencersLocal = this.influencersLocal.map((item) => ({
      ...item,
      selected: this.masterSelected
    }));
    this.fetchCheckedIDs();
  }

  onChangeInfluencerSelection(checked, id) {
    if (this.selectAllInfluencer) {
      if (!checked) {
        if (!this.excludedIdList.includes(id)) {
          this.excludedIdList.push(id);
          this.influencerCount -= 1;
        }
      } else {
        const index = this.excludedIdList.indexOf(id);
        if (index > -1) {
          this.excludedIdList.splice(index, 1);
          this.influencerCount += 1 ;
        }
      }
    } else {
      if (checked) {
        if (!this.includedIdList.includes(id)) {
          this.includedIdList.push(id);
        }
      } else {
        const index = this.includedIdList.indexOf(id);
        if (index > -1) {
          this.includedIdList.splice(index, 1);
        }
      }
    }

    const selectedInfluencers = this.influencersLocal.filter((item) => item.selected);
    this.masterSelected = selectedInfluencers.length === this.influencersLocal.length;
    if (!this.masterSelected && !this.excludedPageList.includes(this.pageNumber + 1)) {
      this.excludedPageList.push(this.pageNumber + 1);
    }
    if (!this.masterSelected && this.includedPageList.includes(this.pageNumber + 1)) {
      const index = this.includedPageList.indexOf(this.pageNumber + 1);
      if (index > -1) {
        this.includedPageList.splice(index, 1);
      }
    }
  }

  bulkStatus() {
    this.influencerStore.dispatch(InfluencersBulkStatus({importJobId: this.importJobId}));
  }

  isImportDataFilled() {
    return this.importData && Object.keys(this.importData).length > 0;
  }

  importDataTableColumns() {
    return this.importData['columns'];
  }

  fetchSelectedItems() {
    this.checkedInfluencer = this.influencersLocal.filter((value, index) => {
      return value.selected;
    });
    this.fetchCheckedIDs();
  }

  fetchCheckedIDs() {
    this.influencersLocal.forEach((value) => {
      if (value.selected) {
        if (this.selectAllInfluencer) {
          const index = this.excludedIdList.indexOf(value.id);
          if (index > -1) {
            this.excludedIdList.splice(index, 1);
            this.influencerCount += 1 ;
          }
        } else {
          if (!this.includedIdList.includes(value.id)) {
            this.includedIdList.push(value.id);
          }
        }
      } else {
        if (this.selectAllInfluencer) {
          if (!this.excludedIdList.includes(value.id)) {
            this.excludedIdList.push(value.id);
            this.influencerCount -= 1;
          }
        } else {
          const index = this.includedIdList.indexOf(value.id);
          if (index > -1) {
            this.includedIdList.splice(index, 1);
          }
        }
      }
    });
  }

  checkAllInfluencer() {
    this.selectAllInfluencer = true;
    this.includedIdList = [];
    this.excludedPageList = [];
    this.includedPageList = [];
    this.excludedIdList = [];
    this.checkUncheckAllInfluencer();
  }

  uncheckAllInfluencer() {
    this.selectAllInfluencer = false;
    this.masterSelected = false;
    this.includedIdList = [];
    this.excludedPageList = [];
    this.includedPageList = [];
    this.excludedIdList = [];
    this.checkUncheckAllInfluencer();
  }

  showDeleteLogs() {
    const modelRef = this._modalService.open(this.logsTable, {
      centered: false,
      size: 'xl',
      keyboard: false,
      backdrop : 'static',
    });
    modelRef.result.then((result) => {
    }, (reason) => {
    });
  }

  close() {
    this.influencersLocal = this.influencersLocal.map((item) => ({
      ...item,
      selected: false
    }));
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
